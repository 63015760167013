
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons-vue';
import Author from './compontent/auth.vue';
import Editor from './compontent/editor.vue';

import {
    copyText
} from "@/utils/common"

export default {
    name: "BasicShipper",
    components: {
        DeleteOutlined,
        PlusOutlined,
        Author,
        Editor
    },
    data() {
        return {
            url: '/user/store_account/list',
            columns: [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t('店铺名称'),
                    dataIndex: 'store_name',
                    width: 250,
                    sorter: true
                },
                {
                    title: this.$t('店铺类型'),
                    dataIndex: 'store_type',
                    sorter: true,
                    width: 150
                },
                {
                    title: this.$t('更新时间'),
                    dataIndex: 'last_download_date',
                    sorter: true,
                    width: 150
                },
                {
                    title: this.$t('share.created'),
                    dataIndex: 'created',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('操作'),
                    key: 'action',
                    slots: {
                        customRender: 'action'
                    }
                }
            ],
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示授权弹窗
            showAuth: false,
            // 默认步骤
            defualtStep: 0,
            // 是否授权成功
            isSuccess: false,
            // 编辑
            showEdit: false
        };
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showAuth = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('share.requesting', 0);

            this.$http.post('/user/store_account/delete/', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('share.select_one_more_then');
            }

            this.$confirm({
                title: this.$t('share.tips'),
                content: this.$t('share.delete_confirm'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('share.requesting', 0);

                    this.$http.post('/user/store_account/delete/', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /**
         * 完成授权
         */
        finish() {
            this.defualtStep = 0;
            this.$store.commit("store/CLEAR", this.$store.state.store)
        },
        /**
         * 复制授权码
         */
        copyToken(row) {
            try {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(row.store_token);
                    this.$message.success("操作成功");
                } else {
                    copyText(row.store_token, () => {
                        this.$message.success("操作成功");
                    })
                }
            } catch (e) {
                console.error(e);
                this.$message.error(e.message);

            }
        }
    },
    mounted() {
        if (this.$route.query.code) {
            const query = this.$route.fullPath.split("?"),
                params = query[1].split("&").map(str => {
                    return str.split("=")
                }).reduce((pre, cur) => {
                    pre[cur[0]] = cur[1];
                    return pre
                }, {});

            this.$router.push("/user/basic/store");
            this.$store.commit("store/SET", params);

            this.defualtStep = 1;
            this.$nextTick(() => {
                this.showAuth = true;
            })
        }
    }
}
