
import {
    LoadingOutlined,
    CloseCircleOutlined
} from "@ant-design/icons-vue"

import {
    ref
} from "vue";

// const DEFAULT_eBay = "https://auth.ebay.com/oauth2/authorize?client_id=chenwei-MoreLink-PRD-bf78bdb77-a72530b2&redirect_uri=chen_wei-chenwei-MoreLin-nwhhr&response_type=code&scope=https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope%20https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope%2Fsell.marketing%20https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope%2Fsell.inventory%20https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope%2Fsell.account%20https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope%2Fsell.fulfillment%20https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope%2Fsell.finances&state="
export default {
    components: {
        LoadingOutlined,
        CloseCircleOutlined
    },
    emits: ["done", "update:visible"],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object,
        defualtStep: Number
    },
    data() {
        return {
            // 提交状态
            loading: false,
            // 是否是修改
            isUpdate: false,
            // 固定高度
            nomalHeight: '300px',
            // 是否跳转中
            isPush: false,
            // 步骤进行结果
            stepResult: {
                status: "process",
                title: ""
            },
            // 授权结果
            result: {
                title: "",
                status: ""
            },
            // 规则
            rules: {
                store_type: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                store_name: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
            }
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
                this.isUpdate = true;
            } else {
                this.form = {};
                this.isUpdate = false;
            }
            if (this.$refs.formRef) {
                this.$refs.formRef.clearValidate();
            }
        }
    },
    computed: {
        isShowFooter() {
            return this.step !== 2;
        }
    },
    methods: {
        /* 更新visible */
        updateVisible(value) {
            this.$emit("update:visible", value);
        },
        /* 保存编辑 */
        save() {
            return new Promise((resolve, reject) => {
                this.$refs.formRef
                    .validate()
                    .then(() => {
                        localStorage.setItem("store_account", JSON.stringify(this.form))

                        this.to_auth();
                        resolve();
                    })
                    .catch(() => {
                        this.stepResult.status = "error";

                        reject()
                    });
            })

        },
        onClose() {
            this.isPush = false;
            this.updateVisible(false);
        },
        // 下一步
        changeStep(type) {
            const c = this.step;
            switch (type) {
                case 'next':
                    if (c == 0) {
                        this.save().then(() => {
                            this.step++;
                        }).catch(() => {})
                    } else if (c >= 2) {
                        this.step = 2;
                    } else {
                        this.step++;
                    }
                    break;
                default:
                    if (c <= 0) {
                        this.step = 0;
                    } else {
                        this.step--;
                    }
                    break;
            }

        },
        // 完成
        finshStep() {
            this.step = 0;
            this.form = {
                store_type: "eBay"
            };
            this.onClose();
            this.$emit("close")
        },
        /**
         * 跳转授权
         */
        to_auth() {
            this.isPush = true;
            this.stepResult.status = "process";

            this.result.title = "正在跳转授权页面";
            this.result.status = "info";

            this.$http.post(`/user/ebeay_authorize/get_authorizeurl`, {
                clienturl: location.href.toString()
            }).then(res => {
                location.href = res.data.msg;
            }).catch((e) => {
                this.fail_result(e);
            })
        },
        /**
         * 新增/修改
         */
        update_store_account() {

            this.result.title = "正在授权";
            this.result.status = "info";

            this.$http.post(`/user/ebeay_authorize/get_token`, {
                code: this.$store.state.store.code
            }).then(response => {
                if (response.data.code == 0) {
                    const form = JSON.parse(localStorage.getItem("store_account"));
                    form.store_token = response.data.msg;

                    this.$http
                        .post("/user/store_account/update", form)
                        .then(res => {
                            if (res.data.code === 0) {
                                this.step++;
                                this.result.title = "授权成功";
                                this.result.status = "success";
                                this.result.msg = form.store_token;

                                this.$emit("done");
                                localStorage.removeItem("store_account");
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(e => {
                            this.loading = false;
                            this.fail_result(e);

                            this.$message.error(e.message);
                        });
                }
            })
        },
        // 拉取订单
        pull_order() {},
        // 失败结果
        fail_result(e) {
            this.result.title = "授权失败";
            this.result.status = "error";
            this.result.msg = e.message;
            this.isPush = false;

            // 步骤状态
            this.stepResult.status = "error";
        }
    },
    mounted() {
        if (this.step == 1) {
            this.update_store_account()
        }
    },
    setup(props) {
        const form = ref(Object.assign({
            store_type: "eBay"
        }, props.data));
        const step = ref(props.defualtStep);

        return {
            step,
            form
        };
    }
};
